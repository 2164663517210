import React from 'react'
import Header from './Header'
import HomeReturnBar from './HomeReturnBar'


const ErrorPage = () => {


  return (
    <div>
        <Header />
        <HomeReturnBar />
        <p>404 Page not found</p>

    </div>
  )
}

export default ErrorPage