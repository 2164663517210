import React from 'react'

const Footer = () => {
  return (
    <div className='powered-by'>
  <p>©Barngrover LLC </p>
<img src='/QRcode_logo.jpg' className='footer-logo' alt='logo'></img>

    </div>
  )
}

export default Footer